import React, { useEffect, useState } from "react";
import {
  Checkbox,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Link,
  List,
  ListItem,
  Typography,
  CircularProgress,
  Input,
} from "@mui/material";
import { useForm } from "react-hook-form";
import ColorPicker from "./components/ColorPicker";
import LinearWithValueLabel from "./components/LinearProgressWithLabel";
import imageAltoEmGorduras from "./img/alto-em-gordura.png";
import imageAltoEmAcucar from "./img/alto-em-acucar.png";
import imageAltoEmSodio from "./img/alto -em-sodio.png";
import { uploadFileToDropbox } from "./utils/uploadToDropbox";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import GenericStepQuestion from "./components/GenericStepQuestion";
import GenericUploadQuestion from "./components/GenericUploadQuestion";
import Step6 from "./components/Step6";
import { ensureToken } from "./utils/authFunctions";
import { getBriefings, saveBriefing } from "./utils/saveBriefing";
import logoFrente from "./img/frente.png";
import logoVerso from "./img/verso logo.png";
import logoVerso2 from "./img/verso 2.png";
import logoVersoComFrase from "./img/verso frase.png";
import BriefingEnviado from "./components/BriefingEnviado";
import { useNavigate } from "react-router-dom";
import { FileUploadOutlined } from "@mui/icons-material";
import BasicModal from "./components/ModalDeConfirmacao";
import Termos from "./components/Termos";
import Header from "./components/Header";
import Footer from "./components/Footer";

function Briefing() {
  const { register, handleSubmit, setValue } = useForm();
  const [termos, setTermos] = useState(false);
  const [hasBriefing, setHasBriefing] = useState(false);
  const [infoOrder, setInfoOrder] = useState(null);
  const [briefingEnviado, setBriefingEnviado] = useState(false);
  const [step, setStep] = useState(() => {
    const storedStep = localStorage.getItem("step");
    return storedStep ? JSON.parse(storedStep) : 1;
  });
  const [formState, setFormState] = useState(() => {
    const storedState = localStorage.getItem("formState");
    return storedState
      ? JSON.parse(storedState)
      : {
          hasArt: null,
          hasLogo: null,
          hasManual: null,
          hasEstampa: null,
          hasFeedback: null,
          selectedEstampa: null,
          hasContactInfo: null,
          contactInfo: {},
          logoPosition: null,
          hasNutritionalTable: null,
          hasHighNutrientsModel: null,
          nutrientsModel: [],
          briefingFinalizado: false,
          estampaEnviada: false,
          hasObservations: null,
          uploading: {
            uploading: false,
            status: "",
          },
          segmento: null,
          fileName: "",
        };
  });
  const [uploading, setUploading] = useState({
    uploading: false,
    status: "",
  });
  const [formData, setFormData] = useState(() => {
    const storedData = localStorage.getItem("formData");
    return storedData ? JSON.parse(storedData) : {};
  });
    const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const totalSteps = 17;

  const updateFormState = (key, value) => {
    setFormState((prevState) => {
      const newState = { ...prevState, [key]: value };
      return newState;
    });
  };

  useEffect(() => {
    if (step === 11 && !formState.hasNutritionalTable) {
      onNextStep(1); // Avança para o próximo passo se a condição não for atendida
    }
    if (step === 13 && !formState.hasHighNutrientsModel) {
      onNextStep(-2); // Avança para o próximo passo se a condição não for atendida
    }
  }, [step, formState.hasNutritionalTable]);

  const validateBriefing = async () => {
    const productBriefing = await getBriefings();
    console.log(productBriefing);
    if (productBriefing !== null) {
      if (productBriefing.product_name.toUpperCase().includes("KRAFT")) {
        navigate(
          `/kraft?produto=${productBriefing.art_sku}&cliente=${productBriefing.ns_customer_id}&numero_pedido=${productBriefing.ns_order_id}`
        );
        return;
      }
      if (productBriefing.product_name.toUpperCase().includes("TAMPA")) {
        navigate(
          `/tampa?produto=${productBriefing.art_sku}&cliente=${productBriefing.ns_customer_id}&numero_pedido=${productBriefing.ns_order_id}`
        );
        return;
      }
      setInfoOrder(productBriefing);
      setHasBriefing(true);
    } else {
      setHasBriefing(false);
    }
  };
  useEffect(() => {
    ensureToken(validateBriefing);

    const produto = new URLSearchParams(window.location.search).get("produto");
    const produtoLS = JSON.parse(localStorage.getItem("produto"));
    console.log(produto, produtoLS);
    if (produto !== produtoLS) {
      console.log("Produto diferente");
      localStorage.setItem("produto", JSON.stringify(produto));
      setStep(1);
      setFormState({
        hasArt: null,
        hasLogo: null,
        hasManual: null,
        hasEstampa: null,
        selectedEstampa: null,
        hasContactInfo: null,
        contactInfo: {},
        logoPosition: null,
        hasNutritionalTable: null,
        hasHighNutrientsModel: null,
        nutrientsModel: [],
        briefingFinalizado: false,
        estampaEnviada: false,
        hasObservations: null,
        uploading: {
          uploading: false,
          status: "",
        },
        segmento: null,
        fileName: "",
      });
      setFormData({});
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("step", JSON.stringify(step));
    localStorage.setItem("formState", JSON.stringify(formState));
    localStorage.setItem("formData", JSON.stringify(formData));
    if (step === 1 && formData["Arte Pronta"]) {
      setFormData((prevData) => {
        // Remove "Arte Pronta" do formData
        const newFormData = { ...prevData };
        delete newFormData["Arte Pronta"];

        // Atualiza o localStorage com o formData modificado
        localStorage.setItem("formData", JSON.stringify(newFormData));

        return newFormData;
      });
    }
  }, [step, formState, formData]);

  useEffect(() => {
    // Rolando para o topo da página sempre que 'step' mudar
    window.scrollTo(0, 0);
  }, [step]);

  // Função para lidar com a seleção do arquivo
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploading({ uploading: true, status: "Enviando arquivo..." });
      const response = await uploadFileToDropbox(file);
      console.log(response);
      if (response) {
        updateFormState("fileName", response.data.uploadResponse.name);
        setUploading({
          uploading: false,
          status: "Arquivo enviado com sucesso!",
        });
        setValue(`${event.target.name}`, response.data.sharingLink.url);
        setFormData((prevData) => ({
          ...prevData,
          [`${event.target.name}`]: response.data.sharingLink.url,
        }));
      } else {
        updateFormState("fileName", "");
        setValue(`${event.target.name}`, null);
        setUploading({ uploading: false, status: `Erro ao enviar arquivo!` });
      }
    }
  };
  const handleCheckboxChangeEstampa = (value) => {
    const newEstampa = formState.selectedEstampa === value ? null : value;

    // Atualiza o estado do formulário
    updateFormState("selectedEstampa", newEstampa);

    // Atualiza o estado local do formulário
    setFormData((prevState) => {
      const newFormData = { ...prevState }; // Cria uma cópia do estado anterior
      delete newFormData["Cor da Estampa"]; // Remove a chave 'Cor da Estampa'
      delete newFormData["Referência para estampa"]; // Remove a chave 'Referência para estampa'
      newFormData.Estampa = newEstampa; // Adiciona ou atualiza a chave 'Estampa' com o novo valor
      return newFormData; // Retorna o novo estado sem as chaves removidas
    });

    // Atualiza o valor do formulário
    setValue("Estampa", newEstampa);
  };

  const handleCheckboxChange = (value) => {
    updateFormState("logoPosition", value);
    setValue("Posição da Logo", value); // Ensure that the correct value is set
    setFormData((prevState) => ({
      ...prevState,
      ["Posição da Logo"]: value,
    }));
  };

  const handleCheckboxChangeNutrientsModel = (value) => {
    setFormState((prevState) => {
      const prevNutrientsModel = Array.isArray(prevState.nutrientsModel)
        ? prevState.nutrientsModel
        : [];
      const newNutrientsModel = prevNutrientsModel.includes(value)
        ? prevNutrientsModel.filter((item) => item !== value)
        : [...prevNutrientsModel, value];

      // Atualiza o valor do campo 'Modelo Nutrientes' no formulário
      setValue("Modelo Nutrientes", newNutrientsModel);
      setFormData((prevState) => ({
        ...prevState,
        ["Modelo Nutrientes"]: newNutrientsModel,
      }));

      return {
        ...prevState,
        nutrientsModel: newNutrientsModel,
      };
    });
  };

  const onNextStep = (skipSteps = 1) => {
    setUploading({ uploading: false, status: "" });
    if (formState.fileName) {
      updateFormState("fileName", null);
    }
    setStep((prevStep) => {
      const nextStep = prevStep + skipSteps;
      return nextStep > 17 ? 17 : nextStep;
    });
    localStorage.setItem("step", JSON.stringify(step));
  };

  const onPreviousStep = (skipSteps = 1) => {
    setStep((prevStep) => prevStep - skipSteps);
  };

  const onSubmit = (data) => {
    console.log(data);
    onNextStep();
  };

  if (briefingEnviado) {
    return (
      <div
        style={{
          background: "linear-gradient(to right, white, #83a802)",
          minHeight: "100vh",
          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "sans-serif",
          padding: "10px",
        }}
      >
        <BriefingEnviado />
      </div>
    );
  }

  if (!hasBriefing) {
    return (
      <div
        style={{
          background: "linear-gradient(to top left, white, #83a802)",
          minHeight: "100vh",
          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Montserrat",
          padding: "10px",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <h1 style={{ textAlign: "center" }}>
            Briefing já preenchido para esse produto!
          </h1>
        </Box>
      </div>
    );
  }

  return (
    <div
      style={{
        background: "linear-gradient(to top left, white, #83a802)",
        minHeight: "100vh",
        maxWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Montserrat",
        padding: "10px",
      }}
    >
      <Header/>
      <h2 style={{ textAlign: "center", fontWeight: "bold"}}>
        Briefing do produto {infoOrder.product_name} - Pedido #
        {infoOrder.ns_order_number}
      </h2>
      <LinearWithValueLabel step={step} totalSteps={totalSteps} />
      <form onSubmit={handleSubmit(onSubmit)}>
        {step === 1 && (
          <GenericStepQuestion
            hasItem={formState.hasArt}
            setHasItem={(value) => updateFormState("hasArt", value)}
            onNextStep={onNextStep}
            question="Você possui arte pronta?"
          />
        )}

        {step === 2 && (
          <Step2
            hasArt={formState.hasArt}
            handleFileChange={handleFileChange}
            onNextStep={onNextStep}
            fileName={formState.fileName}
            uploading={uploading}
            onPreviousStep={onPreviousStep}
            hasLogo={formState.hasLogo}
            setHasLogo={(value) => updateFormState("hasLogo", value)}
            briefingFinalizado={formState.briefingFinalizado}
            finalSteps={13}
            setFormData={setFormData}
            setHasArt={(value) => updateFormState("hasArt", value)}
          />
        )}

        {step === 3 && (
          <Step3
            hasManual={formState.hasManual}
            handleFileChange={handleFileChange}
            onNextStep={onNextStep}
            fileName={formState.fileName}
            uploading={uploading}
            onPreviousStep={onPreviousStep}
            briefingFinalizado={formState.briefingFinalizado}
            register={register}       
            hasLogo={formState.hasLogo}
            setHasLogo={(value) => updateFormState("hasLogo", value)}
            setFormData={setFormData}
            logo={formData.Logo}
            finalSteps={13}
          />
        )}

        {step === 4 && (
          <GenericStepQuestion
            hasItem={formState.hasManual}
            setHasItem={(value) => updateFormState("hasManual", value)}
            onNextStep={onNextStep}
            question="Gostaria de enviar seu manual de marca?"
            onPreviousStep={onPreviousStep}
          />
        )}

        {step === 5 && formState.hasManual === true && (
          <GenericUploadQuestion
            handleFileChange={handleFileChange}
            fileName={formState.fileName}
            uploading={uploading}
            onPreviousStep={onPreviousStep}
            onNextStep={onNextStep}
            question="Envie seu manual de marca aqui"
            gabarito={false}
            fileQuestionButton="Envie seu manual de marca aqui"
            briefingFinalizado={formState.briefingFinalizado}
            nameInput="Manual de Marca"
            steps={0}
            setHasManual={(value) => updateFormState("hasManual", value)}
            hasManual={formState.hasManual}
            finalSteps={9}
          />
        )}

        {step === 5 &&
          (formState.hasManual === false || formState.hasManual === null) && (
            <GenericStepQuestion
              hasItem={formState.hasEstampa}
              setHasItem={(value) => updateFormState("hasEstampa", value)}
              onNextStep={onNextStep}
              question="Você gostaria da sua embalagem com alguma estampa?"
              onPreviousStep={onPreviousStep}
              firstOption="Sim, quero ver sugestões."
              secondOption="Não, quero cor lisa."
            />
          )}
        {step === 6 && (formState.hasEstampa === false || !formState.hasEstampa) && (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <ColorPicker
              register={register}
              setValue={setValue}
              setFormData={setFormData}
              formField={"Estampa"}
            />
            <Button
              variant="contained"
              type="button"
              onClick={() => onNextStep()}
              color="success"
            >
              Próximo
            </Button>
            <Box display="flex" justifyContent="center" margin="10px" />
            <Button
              variant="contained"
              type="button"
              onClick={() => onPreviousStep()}
              color="secondary"
            >
              Anterior
            </Button>
            <Box display="flex" justifyContent="center" margin="10px" />
            {formState.briefingFinalizado && (
              <Button
                variant="contained"
                type="button"
                onClick={() => onNextStep(9)}
                color="info"
              >
                Finalizar
              </Button>
            )}
          </Box>
        )}
        {step === 6 && formState.hasEstampa === true && (
          <Step6
            handleFileChange={handleFileChange}
            onNextStep={onNextStep}
            onPreviousStep={onPreviousStep}
            fileName={formState.fileName}
            uploading={uploading}
            setEstampaEnviada={(value) =>
              updateFormState("estampaEnviada", value)
            }
            register={register}
            setSegmento={(value) => updateFormState("segmento", value)}
            segmento={formState.segmento}
            selectedEstampa={formState.selectedEstampa}
            handleCheckboxChangeEstampa={handleCheckboxChangeEstampa}
            briefingFinalizado={formState.briefingFinalizado}
            setFormData={setFormData}
          />
        )}
        {step === 6.5 && (
          <Box
            display="flex"
            id="box-estampa-referencia"
            flexDirection="column"
            alignSelf="center"
            justifyContent="center"
            alignItems="center"
          >
            <h1 style={{ textAlign: "center" }}>
              Envie a referência da sua estampa aqui
            </h1>
            <ColorPicker
              register={register}
              setValue={setValue}
              setFormData={setFormData}
              formField={"Cor da Estampa"}
            />
            <Box display="flex" justifyContent="center" margin="10px" />
            <textarea
              placeholder="Digite aqui como você gostaria que ficasse sua estampa, se quiser mais de uma cor, favor utilize o seletor acima."
              style={{
                width: "80%",
                height: "100px",
                backgroundColor: "transparent",
                border: "1px solid green",
                borderRadius: "5px",
                fontSize: "16px",
                padding: "10px",
                fontFamily: "sans-serif",
              }}
              value={formData["Referência para estampa"] || ""}
              {...register("Referência para estampa", {
                required: false,
                validate: (value) =>
                  value.trim() !== "" ||
                  "Referência para estampa não pode estar vazia",
              })}
              onChange={(event) => {
                setValue("Referência para estampa", event.target.value);
                setFormData((prevData) => ({
                  ...prevData,
                  "Referência para estampa": event.target.value,
                }));
              }}
            />
            <GenericUploadQuestion
              steps={0.5}
              handleFileChange={handleFileChange}
              fileName={formState.fileName}
              uploading={uploading}
              onPreviousStep={onPreviousStep}
              onNextStep={onNextStep}
              question="Envie sua referência aqui"
              gabarito={false}
              fileQuestionButton="Envie sua referência aqui"
              briefingFinalizado={formState.briefingFinalizado}
              nameInput="Estampa"
              register={register}
              setEstampaEnviada={(value) =>
                updateFormState("estampaEnviada", value)
              }
              estampaEnviada={formState.estampaEnviada}
              finalSteps={7.5}
              previousSteps={0.5}
              referencia={formData["Referência para estampa"]}
            />
          </Box>
        )}
        {step === 7 && (
          <Box
            display="flex"
            flexDirection="column"
            alignContent="center"
            alignItems="center"
          >
            <h1 style={{ textAlign: "center" }}>
              Selecione a posição desejada para aplicação da sua logo:
            </h1>
            <Box
              display="flex"
              flexDirection="column"
              alignContent="center"
              justifyContent="center"
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Logo frente e verso"
                      value="Logo frente e verso"
                      checked={formState.logoPosition === "Logo frente e verso"}
                      onChange={() =>
                        handleCheckboxChange("Logo frente e verso")
                      }
                    />
                  }
                  label="Logo frente e verso"
                />
                <Box>
                  <img
                    src={logoFrente}
                    alt="Logo frente"
                    style={{ width: 80, height: 120 }}
                  />
                  <img
                    src={logoVerso}
                    alt="Logo verso"
                    style={{ width: 80, height: 120 }}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Logo frente e verso liso"
                      value="Logo frente e verso liso"
                      checked={
                        formState.logoPosition === "Logo frente e verso liso"
                      }
                      onChange={() =>
                        handleCheckboxChange("Logo frente e verso liso")
                      }
                    />
                  }
                  label="Logo frente e verso liso"
                />
                <Box>
                  <img
                    src={logoFrente}
                    alt="Logo frente"
                    style={{ width: 80, height: 120 }}
                  />
                  <img
                    src={logoVerso2}
                    alt="Logo verso"
                    style={{ width: 80, height: 120 }}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value="Logo frente e verso com frase"
                      name="Logo frente e verso liso"
                      checked={
                        formState.logoPosition ===
                        "Logo frente e verso com frase"
                      }
                      onChange={() =>
                        handleCheckboxChange("Logo frente e verso com frase")
                      }
                    />
                  }
                  label="Logo frente e verso com frase"
                />
                <Box>
                  <img
                    src={logoFrente}
                    alt="Logo frente"
                    style={{ width: 80, height: 120 }}
                  />
                  <img
                    src={logoVersoComFrase}
                    alt="Logo verso"
                    style={{ width: 80, height: 120 }}
                  />
                </Box>
              </Box>
              {formState.logoPosition &&
                formState.logoPosition === "Logo frente e verso com frase" && (
                  <TextField
                    {...register("Frase", { required: true })}
                    value={formState.Frase || ""}
                    onChange={(e) => {
                      setFormState((prev) => ({
                        ...prev,
                        Frase: e.target.value,
                      }));
                      setFormData((prevData) => ({
                        ...prevData,
                        Frase: e.target.value,
                      }));
                    }}
                    placeholder="Informe a frase desejada"
                  ></TextField>
                )}
            </Box>
            <Box display="flex" justifyContent="center" margin="10px" />
            <Button
              disabled={!formState.logoPosition || (formState.logoPosition === "Logo frente e verso com frase" && !formState.Frase)}
              variant="contained"
              type="button"
              onClick={() => onNextStep()}
              color="success"
            >
              Próximo
            </Button>
            <Box display="flex" justifyContent="center" margin="10px" />
            <Button
              variant="contained"
              type="button"
              onClick={() => onPreviousStep()}
              color="secondary"
            >
              Anterior
            </Button>
          </Box>
        )}
        {step === 8 && (
          <GenericStepQuestion
            hasItem={formState.hasContactInfo}
            setHasItem={(value) => updateFormState("hasContactInfo", value)}
            onNextStep={onNextStep}
            question="Gostaria de adicionar informações de contato e redes sociais na sua embalagem?"
            onPreviousStep={onPreviousStep}
            nextSteps={formState.hasContactInfo === true ? 1 : 2}
          />
        )}
        {step === 9 && (
          <Box
            display="flex"
            flexDirection="column"
            alignContent="space-around"
            alignItems="center"
          >
            <h1 style={{ textAlign: "center" }}>
              Informe as informações de contato e redes sociais que deseja
              adicionar:
            </h1>
            <TextField
              label="Telefone"
              color="info"
              {...register("Telefone", { required: true })}
              value={formState.contactInfo.Telefone}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  contactInfo: {
                    ...prev.contactInfo,
                    Telefone: e.target.value,
                  },
                }));
                setFormData((prevData) => ({
                  ...prevData,
                  Telefone: e.target.value,
                }));
              }}
            />
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <TextField
              label="Whatsapp"
              color="info"
              {...register("Whatsapp", { required: true })}
              value={formState.contactInfo.Whatsapp}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  contactInfo: {
                    ...prev.contactInfo,
                    Whatsapp: e.target.value,
                  },
                }));

                setFormData((prevData) => ({
                  ...prevData,
                  WhatsApp: e.target.value,
                }));
              }}
            />
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <TextField
              label="Instagram"
              color="info"
              value={formState.contactInfo.Instagram}
              {...register("Instagram", { required: true })}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  contactInfo: {
                    ...prev.contactInfo,
                    Instagram: e.target.value,
                  },
                }));
                setFormData((prevData) => ({
                  ...prevData,
                  Instagram: e.target.value,
                }));
              }}
            />
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <TextField
              label="Facebook"
              color="info"
              {...register("Facebook", { required: true })}
              value={formState.contactInfo.Facebook}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  contactInfo: {
                    ...prev.contactInfo,
                    Facebook: e.target.value,
                  },
                }));
                setFormData((prevData) => ({
                  ...prevData,
                  Facebook: e.target.value,
                }));
              }}
            />
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <Button
              variant="contained"
              type="button"
              onClick={() => onNextStep()}
              color="success"
            >
              Próximo
            </Button>
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <Button
              variant="contained"
              type="button"
              onClick={() => onPreviousStep()}
              color="secondary"
            >
              Anterior
            </Button>
          </Box>
        )}
        {step === 10 && (
          <GenericStepQuestion
            hasItem={formState.hasNutritionalTable}
            setHasItem={(value) =>
              updateFormState("hasNutritionalTable", value)
            }
            onNextStep={onNextStep}
            question="Deseja incluir Tabela Nutricional?"
            onPreviousStep={onPreviousStep}
            nextSteps={formState.hasNutritionalTable === true ? 1 : 2}
          />
        )}
        {step === 11 && formState.hasNutritionalTable === true && (
          <GenericUploadQuestion
            handleFileChange={handleFileChange}
            fileName={formState.fileName}
            uploading={uploading}
            onPreviousStep={onPreviousStep}
            onNextStep={onNextStep}
            question="Envie a tabela nutricional aqui"
            gabarito={false}
            fileQuestionButton="Envie a tabela nutricional aqui"
            briefingFinalizado={formState.briefingFinalizado}
            nameInput="Tabela Nutricional"
            steps={1}
            setHasNutritionalTable={(value) =>
              updateFormState("hasNutritionalTable", value)
            }
            hasNutritionalTable={formState.hasNutritionalTable}
            finalSteps={3}
          />
        )}
        {step === 12 && (
          <Box
            display="flex"
            flexDirection="column"
            alignContent="center"
            alignItems="center"
          >
            <h1 style={{ textAlign: "center" }}>
              Deseja incluir modelo com alto teor de nutrientes?
            </h1>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.hasHighNutrientsModel === true}
                  onChange={() =>
                    updateFormState("hasHighNutrientsModel", true)
                  }
                />
              }
              label="Sim"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.hasHighNutrientsModel === false}
                  onChange={() =>
                    updateFormState("hasHighNutrientsModel", false)
                  }
                />
              }
              label="Não"
            />
            <Button
              color="success"
              variant="contained"
              type="button"
              onClick={() => {
                onNextStep(formState.hasHighNutrientsModel === true ? 1 : 2);
              }}
              disabled={formState.hasHighNutrientsModel === null}
            >
              Próximo
            </Button>
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <Button
              variant="contained"
              type="button"
              onClick={() => onPreviousStep(2)}
              color="secondary"
            >
              Anterior
            </Button>
          </Box>
        )}
        {step === 13 && formState.hasHighNutrientsModel === true && (
          <Box
            display="flex"
            flexDirection="column"
            alignContent="center"
            alignItems="center"
          >
            <h1 style={{ textAlign: "center" }}>
              Selecione as opções desejadas
            </h1>
            <FormControlLabel
              control={
                <Checkbox
                  value="Alto em açúcar adicionado"
                  name="Alto em açúcar adicionado"
                  checked={formState.nutrientsModel.includes(
                    "Alto em açúcar adicionado"
                  )}
                  onChange={() =>
                    handleCheckboxChangeNutrientsModel(
                      "Alto em açúcar adicionado"
                    )
                  }
                />
              }
              label={
                <Box display="flex" alignItems="center">
                  <img
                    src={imageAltoEmAcucar}
                    alt="Alto em gordura"
                    style={{ marginLeft: 8, width: 150, height: 35 }}
                  />
                </Box>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="Alto em gordura saturada"
                  name="Alto em gordura saturada"
                  checked={formState.nutrientsModel.includes(
                    "Alto em gordura saturada"
                  )}
                  onChange={() =>
                    handleCheckboxChangeNutrientsModel(
                      "Alto em gordura saturada"
                    )
                  }
                />
              }
              label={
                <Box display="flex" alignItems="center">
                  <img
                    src={imageAltoEmGorduras}
                    alt="Alto em gordura"
                    style={{ marginLeft: 8, width: 150, height: 35 }}
                  />
                </Box>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="Alto em sódio"
                  name="Alto em sódio"
                  checked={formState.nutrientsModel.includes("Alto em sódio")}
                  onChange={() =>
                    handleCheckboxChangeNutrientsModel("Alto em sódio")
                  }
                />
              }
              label={
                <Box display="flex" alignItems="center">
                  <img
                    src={imageAltoEmSodio}
                    alt="Alto em gordura"
                    style={{ marginLeft: 8, width: 150, height: 35 }}
                  />
                </Box>
              }
            />
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <Button
              color="success"
              variant="contained"
              type="button"
              onClick={() => onNextStep()}
              disabled={formState.nutrientsModel.length === 0}
            >
              Próximo
            </Button>
            <Box display="flex" justifyContent="center" margin="10px"></Box>
            <Button
              variant="contained"
              type="button"
              onClick={() => onPreviousStep()}
              color="secondary"
            >
              Anterior
            </Button>
            {formState.briefingFinalizado && (
              <Box justifyContent="center" marginTop="10px">
                <Box justifyContent="center" margin="10px" />
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => onNextStep(2)}
                >
                  Finalizar
                </Button>
              </Box>
            )}
          </Box>
        )}
        {step === 14 && (
          <GenericStepQuestion finalSteps={15} question={"Deseja anexar mais alguma referência ou deixar alguma observação?"} hasItem={formState.hasObservations} setHasItem={(value) => updateFormState("hasObservations", value)} onNextStep={onNextStep} onPreviousStep={onPreviousStep} nextSteps={formState.hasObservations ? 1 : 2} />
        )}
        {step === 15 && (
          <Box textAlign="center">
            <Box marginTop={3}/>
            <textarea
              placeholder="Deixe aqui sua observação. Ex: Gostaria de uma embalagem mais colorida, com mais informações sobre o produto, etc."
              style={{
                width: "90%",
                height: "100px",
                backgroundColor: "transparent",
                border: "1px solid green",
                borderRadius: "5px",
                fontSize: "16px",
                padding: "10px",
                textAlign: "center",
                fontFamily: "sans-serif",
              }}
              value={formData["Observações"] || ""}
              {...register("Observações", {
                required: false,
                validate: (value) =>
                  value.trim() !== "" ||
                  "Observações não pode estar vazia",
              })}
              onChange={(event) => {
                setValue("Observações", event.target.value);
                setFormData((prevData) => ({
                  ...prevData,
                  "Observações": event.target.value,
                }));
              }}
            />
                        <Box height="20px" />
            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                <Input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    multiple
                    name="Mais Referências"
                    type="file"
                    onChange={handleFileChange}
                />
                <label htmlFor="contained-button-file">
                    <Button
                        variant="outlined"
                        component="span"
                        color="success"
                        startIcon={<FileUploadOutlined />}
                    >
                        Envie aqui o arquivo
                    </Button>
                </label>

                {formState.fileName && (
                    <Typography variant="body1" mt={2}>
                        Arquivo selecionado: {formState.fileName}
                    </Typography>
                )}

                {uploading.status && (
                    <Typography variant="body1" mt={2}>
                        {uploading.status} {uploading.uploading && <CircularProgress size={20} />}
                    </Typography>
                )}
                <p style={{ color: "#871111", textAlign: "center" }}><span>*</span>Aceitamos arquivos nas extensões Illustrator, Photoshop, Corel Draw, PDF ou PNG de alta resolução (mínimo 300dpi)</p>
                <Button
                  color="success"
                  variant="contained"
                  type="button"
                  onClick={() => onNextStep()}
                  disabled={formState.fileName === ""}
                >
                  Próximo
                </Button>
                <Box display="flex" justifyContent="center" margin="10px"></Box>
                <Button
                  variant="contained"
                  type="button"
                  onClick={() => onPreviousStep()}
                  color="secondary"
                >
                  Anterior
                </Button>
            
            </Box>
        </Box>
        )}
        {step === 16 && (
          <Box
            display="flex"
            flexDirection="column"
            alignContent="center"
            alignItems="center"
          >
            <h1 style={{ textAlign: "center" }}>
              Revise as informações no próximo passo
            </h1>
            <Button
              variant="contained"
              type="button"
              onClick={() => {
                if(formData["Arte Pronta"]){
                  onPreviousStep(13)
                }
                onPreviousStep(2)
              }}
              color="secondary"
            >
              Anterior
            </Button>
            <Box display="flex" justifyContent="center" margin="10px" />
            <Button variant="contained" type="submit" color="success">
              Revisar
            </Button>
          </Box>
        )}
      </form>
      {step === 17 && (
        <Box display="flex" flexDirection="column">
          <h1 style={{ textAlign: "center" }}>Revise as informações</h1>
          {Object.keys(formData).map((key) => (
            <Box key={key} display="flex" mb={2} flexDirection="column">
              <p>
                <span style={{ fontWeight: "bold" }}>{key}</span>:
                {key === "Estampa" ||
                key === "Cor da Estampa" ||
                key === "Logo" ? (
                  formData[key].includes("http") ? (
                    <Box>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        margin="5px"
                      />
                      <Link
                        href={formData[key]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Clique aqui para visualizar
                      </Link>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginTop="10px"
                      />
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          let stepValue = 1; // Default step
                          if (key === "Logo") stepValue = 13;
                          else if (key === "Manual de Marca") stepValue = 11;
                          else if (
                            key === "Estampa" ||
                            key === "Cor da Estampa"
                          )
                            stepValue = 9;
                          else if (key === "Tabela Nutricional") stepValue = 4;
                          updateFormState("briefingFinalizado", true);
                          onPreviousStep(stepValue);
                        }}
                      >
                        Editar
                      </Button>
                    </Box>
                  ) : (
                    <Box>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        margin="5px"
                      />
                      {formData[key]}
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginTop="10px"
                      />
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          let stepValue = 1; // Default step
                          if (key === "Logo") stepValue = 15;
                          else if (key === "Manual de Marca") stepValue = 11;
                          else if (
                            key === "Estampa" ||
                            key === "Cor da Estampa"
                          )
                            stepValue = 9;
                          else if (key === "Tabela Nutricional") stepValue = 4;
                          updateFormState("briefingFinalizado", true);
                          onPreviousStep(stepValue);
                        }}
                      >
                        Editar
                      </Button>
                    </Box>
                  )
                ) : key === "Modelo Nutrientes" ? (
                  <Box>
                    {formData[key].map((item, index) => (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent="flex-start"
                        margin="5px"
                      >
                        <List>
                          <ListItem>* {item}</ListItem>
                        </List>
                      </Box>
                    ))}
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginTop="10px"
                    >
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => onPreviousStep(2)}
                      >
                        Editar
                      </Button>
                    </Box>
                  </Box>
                ) : key === "Arte Pronta" ||
                  key === "Mais Referências" ||
                  key === "Manual de Marca" ||
                  key === "Estampa" ||
                  key === "Tabela Nutricional" ||
                  key === "Logo" ||
                  key === "Referência" ||
                  key === "Cor da Estampa" ? (
                  <Box>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      margin="5px"
                    />
                    <Link href={formData[key]} target="_blank" rel="noreferrer">
                      Clique aqui para visualizar
                    </Link>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginTop="10px"
                    >
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          let stepValue = 1; // Default step
                          if (key === "Logo") stepValue = 13;
                          else if (key === "Manual de Marca") stepValue = 11;
                          else if (
                            key === "Estampa" ||
                            key === "Cor da Estampa"
                          )
                            stepValue = 9;
                          else if (key === "Tabela Nutricional") stepValue = 4;
                          else if ((key === "Arte Pronta" || key === "Referência")) stepValue = 15;
                          else if (key === "Mais Referências") stepValue = 2;
                          updateFormState("briefingFinalizado", true);
                          onPreviousStep(stepValue);
                        }}
                      >
                        Editar
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <TextField
                    variant="standard"
                    sx={{ width: "100%", height: "40px" }}
                    value={formData[key]}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        [key]: e.target.value,
                      }))
                    }
                  />
                )}
              </p>
            </Box>
          ))}
          <Box mt={2} mb={2}>
            <BasicModal></BasicModal>
          </Box>
          <Box mt={2} mb={2}>
            <Termos setTermos={setTermos} termos={termos} />
          </Box>       
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              variant="contained"
              type="button"
              onClick={() => onPreviousStep()}
              color="secondary"
            >
              Anterior
            </Button>
            <Box display="flex" justifyContent="center" margin="10px" />
            <Button
              variant="contained"
              type="submit"
              color="success"
              disabled={!termos}
              onClick={async () => {
                const cliente = new URLSearchParams(window.location.search).get(
                  "cliente"
                );
                const pedido = new URLSearchParams(window.location.search).get(
                  "numero_pedido"
                );
                const produto = new URLSearchParams(window.location.search).get(
                  "produto"
                );
                const briefing = await saveBriefing(
                  formData,
                  pedido,
                  cliente,
                  produto,
                  uploading,
                  setUploading,
                  setBriefingEnviado,
                  infoOrder
                );
                console.log("Briefing no botão" + briefing);
              }}
            >
              Enviar
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            margin="10px"
            alignItems="center"
          />
          <Box textAlign="center">
            {uploading.status && (
              <Typography variant="body1" mt={2}>
                {uploading.status}{" "}
                {uploading.uploading && <CircularProgress size={20} />}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <Footer />
    </div>
  );
}

export default Briefing;
