import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress 
          variant="determinate"
          {...props}
          sx={{ 
            height: 25, // Aumenta a espessura da barra
            background: 'white', // Cor do fundo da barra (opcional)
            '& .MuiLinearProgress-bar': {
              background: "linear-gradient(to right, black, #04d600)", // Cor da barra de progresso
            },
            borderRadius: 5, // Borda arredondada
          }}
          
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary" fontWeight="bold">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({ step, totalSteps }) {
  const progress = (step / totalSteps) * 100;

  return (
    <Box sx={{ width: {
      xs: "100%",
      sm: "80%",
      md: "70%",
      lg: "60%",
      xl: "50%"
    } }}>
      <LinearProgressWithLabel value={progress}  />
    </Box>
  );
}

LinearWithValueLabel.propTypes = {
  step: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};
