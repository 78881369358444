
import estampa1 from "../ESCRITÓRIO/Escritório 01.png";
import estampa2 from "../ESCRITÓRIO/Escritório 02.png";
import estampa3 from "../ESCRITÓRIO/Escritório 03.png";
import estampa4 from "../ESCRITÓRIO/Escritório 04.png";
import estampa5 from "../ESCRITÓRIO/Escritório 05.png";
import estampa6 from "../ESCRITÓRIO/Escritório 06.png";
import estampa7 from "../ESCRITÓRIO/Escritório 07.png";
import estampa8 from "../ESCRITÓRIO/Escritório 08.png";
import estampa9 from "../ESCRITÓRIO/Escritório 09.png";
import estampa10 from"../ESCRITÓRIO/Escritório 010.png";
import estampa11 from"../ESCRITÓRIO/Escritório 011.png";
import estampa12 from"../ESCRITÓRIO/Escritório 012.png";
 
 export  const imagensEscritorio = [
      {
        "nome": "Escritório 01",
        "tipo": "png",
        "caminho": estampa1
      },
      {
        "nome": "Escritório 02",
        "tipo": "png",
        "caminho": estampa2
      },
      {
        "nome": "Escritório 03",
        "tipo": "png",
        "caminho": estampa3
      },
      {
        "nome": "Escritório 04",
        "tipo": "png",
        "caminho": estampa4
      },
      {
        "nome": "Escritório 05",
        "tipo": "png",
        "caminho": estampa5
      },
      {
        "nome": "Escritório 06",
        "tipo": "png",
        "caminho": estampa6
      },
      {
        "nome": "Escritório 07",
        "tipo": "png",
        "caminho": estampa7
      },
      {
        "nome": "Escritório 08",
        "tipo": "png",
        "caminho": estampa8
      },
      {
        "nome": "Escritório 09",
        "tipo": "png",
        "caminho": estampa9
      },
      {
        "nome": "Escritório 10",
        "tipo": "png",
        "caminho": estampa10
      },
      {
        "nome": "Escritório 11",
        "tipo": "png",
        "caminho": estampa11
      },
      {
        "nome": "Escritório 12",
        "tipo": "png",
        "caminho": estampa12
      },
    ]
  