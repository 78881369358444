
import estampa1 from "../CLÍNICA/Clinica 01.png";
import estampa2 from  "../CLÍNICA/Clinica 02.png";
import estampa3 from  "../CLÍNICA/Clinica 03.png"; 
import estampa4 from  "../CLÍNICA/Clinica 04.png"; 
import estampa5 from  "../CLÍNICA/Clinica 05.png"; 
import estampa6 from  "../CLÍNICA/Clinica 06.png"; 
import estampa7 from  "../CLÍNICA/Clinica 07.png"; 
import estampa8 from  "../CLÍNICA/Clinica 08.png"; 
import estampa9 from  "../CLÍNICA/Clinica 09.png"; 
import estampa10 from "../CLÍNICA/Clinica 010.png"; 
import estampa11 from "../CLÍNICA/Clinica 011.png"; 
import estampa12 from "../CLÍNICA/Clinica 012.png"; 
import estampa13 from "../CLÍNICA/Clinica 013.png"; 
import estampa14 from "../CLÍNICA/Clinica 014.png"; 
import estampa15 from "../CLÍNICA/Clinica 015.png"; 
import estampa16 from "../CLÍNICA/Clinica 016.png"; 
import estampa18 from "../CLÍNICA/Clinica 018.png"; 
import estampa19 from "../CLÍNICA/Clinica 019.png"; 
import estampa20 from "../CLÍNICA/Clinica 020.png"; 
import estampa21 from "../CLÍNICA/Clinica 021.png"; 
import estampa22 from "../CLÍNICA/Clinica 022.png";
import estampa23 from "../CLÍNICA/Clínica 023.png";

 
 export  const imagensClinica = [
      {
        "nome": "Clinica 01",
        "tipo": "png",
        "caminho": estampa1
      },
      {
        "nome": "Clinica 02",
        "tipo": "png",
        "caminho": estampa2
      },
      {
        "nome": "Clinica 03",
        "tipo": "png",
        "caminho": estampa3
      },
      {
        "nome": "Clinica 04",
        "tipo": "png",
        "caminho": estampa4
      },
      {
        "nome": "Clinica 05",
        "tipo": "png",
        "caminho": estampa5
      },
      {
        "nome": "Clinica 06",
        "tipo": "png",
        "caminho": estampa6
      },
      {
        "nome": "Clinica 07",
        "tipo": "png",
        "caminho": estampa7
      },
      {
        "nome": "Clinica 08",
        "tipo": "png",
        "caminho": estampa8
      },
      {
        "nome": "Clinica 09",
        "tipo": "png",
        "caminho": estampa9
      },
      {
        "nome": "Clinica 10",
        "tipo": "png",
        "caminho": estampa10
      },
      {
        "nome": "Clinica 11",
        "tipo": "png",
        "caminho": estampa11
      },
      {
        "nome": "Clinica 12",
        "tipo": "png",
        "caminho": estampa12
      },
      {
        "nome": "Clinica 13",
        "tipo": "png",
        "caminho": estampa13
      },
      {
        "nome": "Clinica 14",
        "tipo": "png",
        "caminho": estampa14
      },
      {
        "nome": "Clinica 15",
        "tipo": "png",
        "caminho": estampa15
      },
      {
        "nome": "Clinica 16",
        "tipo": "png",
        "caminho": estampa16
      },
      {
        "nome": "Clinica 18",
        "tipo": "png",
        "caminho": estampa18
      },
      {
        "nome": "Clinica 19",
        "tipo": "png",
        "caminho": estampa19
      },
      {
        "nome": "Clinica 20",
        "tipo": "png",
        "caminho": estampa20
      },
      {
        "nome": "Clinica 21",
        "tipo": "png",
        "caminho": estampa21
      },
      {
        "nome": "Clinica 22",
        "tipo": "png",
        "caminho": estampa22
      },
      {
        "nome": "Clinica 23",
        "tipo": "png",
        "caminho": estampa23
      },
    ]
  
  