
import estampa1 from  "../PADARIA/Padaria 01.png"
import estampa2 from  "../PADARIA/Padaria 02.png"
import estampa3 from  "../PADARIA/Padaria 03.png"
import estampa4 from  "../PADARIA/Padaria 04.png"
import estampa5 from "../PADARIA/Padaria 05.png" 
import estampa6 from  "../PADARIA/Padaria 06.png"
import estampa7 from  "../PADARIA/Padaria 07.png"
import estampa8 from  "../PADARIA/Padaria 08.png"
import estampa9 from  "../PADARIA/Padaria 09.png"
import estampa10 from "../PADARIA/Padaria 010.png"
import estampa11 from "../PADARIA/Padaria 011.png"
import estampa12 from "../PADARIA/Padaria 012.png"
import estampa13 from"../PADARIA/Padaria 013.png"
import estampa14 from "../PADARIA/Padaria 014.png"
 
 export  const imagensPadaria = [
      {
        "nome": "Padaria 01",
        "tipo": "png",
        "caminho": estampa1
      },
      {
        "nome": "Padaria 02",
        "tipo": "png",
        "caminho": estampa2
      },
      {
        "nome": "Padaria 03",
        "tipo": "png",
        "caminho": estampa3
      },
      {
        "nome": "Padaria 04",
        "tipo": "png",
        "caminho": estampa4
      },
      {
        "nome": "Padaria 05",
        "tipo": "png",
        "caminho": estampa5
      },
      {
        "nome": "Padaria 06",
        "tipo": "png",
        "caminho": estampa6
      },
      {
        "nome": "Padaria 07",
        "tipo": "png",
        "caminho": estampa7
      },
      {
        "nome": "Padaria 08",
        "tipo": "png",
        "caminho": estampa8
      },
      {
        "nome": "Padaria 09",
        "tipo": "png",
        "caminho": estampa9
      },
      {
        "nome": "Padaria 10",
        "tipo": "png",
        "caminho": estampa10
      },
      {
        "nome": "Padaria 11",
        "tipo": "png",
        "caminho": estampa11
      },
      {
        "nome": "Padaria 12",
        "tipo": "png",
        "caminho": estampa12
      },
      {
        "nome": "Padaria 13",
        "tipo": "png",
        "caminho": estampa13
      },
      {
        "nome": "Padaria 14",
        "tipo": "png",
        "caminho": estampa14
      }
    ]
  