
import estampa1 from "../AÇAÍ/Açaí 01.png";
import estampa2 from "../AÇAÍ/Açaí 02.png";
import estampa3 from "../AÇAÍ/Açaí 03.png";
import estampa4 from "../AÇAÍ/Açaí 04.png";
import estampa5 from "../AÇAÍ/Açaí 05.png";
import estampa6 from "../AÇAÍ/Açaí 06.png";
import estampa7 from "../AÇAÍ/Açaí 07.png";
import estampa8 from "../AÇAÍ/Açaí 08.png";
import estampa9 from "../AÇAÍ/Açaí 09.png";
import estampa10 from"../AÇAÍ/Açaí 010.png";
import estampa11 from"../AÇAÍ/Açaí 011.png";
import estampa12 from"../AÇAÍ/Açaí 012.png";
import estampa13 from"../AÇAÍ/Açaí 013.png";
import estampa14 from"../AÇAÍ/Açaí 014.png";
import estampa15 from"../AÇAÍ/Açaí 015.png";
import estampa16 from"../AÇAÍ/Açaí 016.png";
import estampa17 from"../AÇAÍ/Açaí 017.png";
import estampa18 from"../AÇAÍ/Açaí 018.png";
import estampa19 from"../AÇAÍ/Açaí 019.png";
import estampa20 from"../AÇAÍ/Açaí 020.png";
import estampa21 from"../AÇAÍ/Açaí 021.png";


 
 export  const imagensAcai = [
      {
        "nome": "AÇAÍ 01",
        "tipo": "png",
        "caminho": estampa1
      },
      {
        "nome": "AÇAÍ 02",
        "tipo": "png",
        "caminho": estampa2
      },
      {
        "nome": "AÇAÍ 03",
        "tipo": "png",
        "caminho": estampa3
      },
      {
        "nome": "AÇAÍ 04",
        "tipo": "png",
        "caminho": estampa4
      },
      {
        "nome": "AÇAÍ 05",
        "tipo": "png",
        "caminho": estampa5
      },
      {
        "nome": "AÇAÍ 06",
        "tipo": "png",
        "caminho": estampa6
      },
      {
        "nome": "AÇAÍ 07",
        "tipo": "png",
        "caminho": estampa7
      },
      {
        "nome": "AÇAÍ 08",
        "tipo": "png",
        "caminho": estampa8
      },
      {
        "nome": "AÇAÍ 09",
        "tipo": "png",
        "caminho": estampa9
      },
      {
        "nome": "AÇAÍ 10",
        "tipo": "png",
        "caminho": estampa10
      },
      {
        "nome": "AÇAÍ 11",
        "tipo": "png",
        "caminho": estampa11
      },
      {
        "nome": "AÇAÍ 12",
        "tipo": "png",
        "caminho": estampa12
      },
      {
        "nome": "AÇAÍ 13",
        "tipo": "png",
        "caminho": estampa13
      },
      {
        "nome": "AÇAÍ 14",
        "tipo": "png",
        "caminho": estampa14
      },
      {
        "nome": "AÇAÍ 15",
        "tipo": "png",
        "caminho": estampa15
      },
      {
        "nome": "AÇAÍ 16",
        "tipo": "png",
        "caminho": estampa16
      },
      {
        "nome": "AÇAÍ 17",
        "tipo": "png",
        "caminho": estampa17
      },
      {
        "nome": "AÇAÍ 18",
        "tipo": "png",
        "caminho": estampa18
      },
      {
        "nome": "AÇAÍ 19",
        "tipo": "png",
        "caminho": estampa19
      },
      {
        "nome": "AÇAÍ 20",
        "tipo": "png",
        "caminho": estampa20
      },
      {
        "nome": "AÇAÍ 21",
        "tipo": "png",
        "caminho": estampa21
      },
    ]
  