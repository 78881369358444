import React from "react";
import GenericStepQuestion from "./GenericStepQuestion";
import ReplicacaoArte from "./ReplicacaoArte";

const Step2 = ({
  hasArt,
  handleFileChange,
  onNextStep,
  fileName,
  uploading,
  onPreviousStep,
  hasLogo,
  setHasLogo,
  briefingFinalizado,
  finalSteps,
  setFormData,
  setHasArt,
}) => {
  if (hasArt === false)
    return (
      <GenericStepQuestion
        hasItem={hasLogo}
        setHasItem={setHasLogo}
        onNextStep={onNextStep}
        question="Você possui logo?"
        onPreviousStep={onPreviousStep}
        nameInput="Logo"
        firstOption="Sim"
        secondOption="Não"
      />
    );
  return (
    <ReplicacaoArte
      handleFileChange={handleFileChange}
      fileName={fileName}
      uploading={uploading}
      onPreviousStep={onPreviousStep}
      onNextStep={onNextStep}
      question="Selecione a arte que deseja replicar"
      fileQuestionButton="Selecionar Arte"
      nameInput="Arte Pronta"
      hasManual={hasLogo}
      setHasManual={setHasLogo}
      finalSteps={finalSteps}
      previousSteps={1}
      referencia={hasLogo}
      setFormData={setFormData}
      setHasArt={setHasArt}
    />
  );
};

export default Step2;
