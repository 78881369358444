import config from "../../../config/config";

function sanitizeFileName(fileName) {
  // Substitui caracteres não ASCII por um caractere permitido
  return fileName.replace(/[^\x00-\x7F]/g, '_');
}
  // Função para fazer upload de arquivos
  export const uploadFileToDropbox = async (file) => {
    const numero_pedido = new URLSearchParams(window.location.search).get('numero_pedido');
    const produto = new URLSearchParams(window.location.search).get('produto');
    const cliente = new URLSearchParams(window.location.search).get('cliente');
    try {
      const response = await fetch(`${config.apiUrl}uploadToDropbox?cliente=${cliente}&numero_pedido=${numero_pedido}&produto=${produto}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/octet-stream',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
          'file-name': sanitizeFileName(file.name) // Nome do arquivo pode ser passado como header
        },
        body: file // Envia o arquivo diretamente
      });
      
      const result = await response.json();
      console.log('Upload bem-sucedido:', result);
      return result;
    } catch (error) {
      console.error('Erro ao fazer upload:', error.message);
      return null;
    }
  };
