
import estampa1 from "../CAFETERIA/Cafeteria 01.png";
import estampa2 from  "../CAFETERIA/Cafeteria 02.png";
import estampa3 from  "../CAFETERIA/Cafeteria 03.png";
import estampa4 from  "../CAFETERIA/Cafeteria 04.png";
import estampa5 from  "../CAFETERIA/Cafeteria 05.png";
import estampa6 from  "../CAFETERIA/Cafeteria 06.png";
import estampa7 from  "../CAFETERIA/Cafeteria 07.png";
import estampa8 from  "../CAFETERIA/Cafeteria 08.png";
import estampa9 from  "../CAFETERIA/Cafeteria 09.png";
import estampa10 from "../CAFETERIA/Cafeteria 010.png";
import estampa11 from "../CAFETERIA/Cafeteria 011.png";
import estampa12 from "../CAFETERIA/Cafeteria 012.png";
import estampa13 from "../CAFETERIA/Cafeteria 013.png";
import estampa14 from "../CAFETERIA/Cafeteria 014.png";
import estampa16 from "../CAFETERIA/Cafeteria 016.png";
import estampa17 from "../CAFETERIA/Cafeteria 017.png";
import estampa18 from "../CAFETERIA/Cafeteria 018.png";
import estampa19 from "../CAFETERIA/Cafeteria 019.png";
import estampa20 from "../CAFETERIA/Cafeteria 020.png";
import estampa21 from "../CAFETERIA/Cafeteria 021.png";
import estampa22 from "../CAFETERIA/Cafeteria 022.png";
import estampa23 from "../CAFETERIA/Cafeteria 023.png";
import estampa24 from "../CAFETERIA/Cafeteria 024.png";
 
 export  const imagensCafeteria = [
      {
        "nome": "Cafeteria 01",
        "tipo": "png",
        "caminho": estampa1
      },
      {
        "nome": "Cafeteria 02",
        "tipo": "png",
        "caminho": estampa2
      },
      {
        "nome": "Cafeteria 03",
        "tipo": "png",
        "caminho": estampa3
      },
      {
        "nome": "Cafeteria 04",
        "tipo": "png",
        "caminho": estampa4
      },
      {
        "nome": "Cafeteria 05",
        "tipo": "png",
        "caminho": estampa5
      },
      {
        "nome": "Cafeteria 06",
        "tipo": "png",
        "caminho": estampa6
      },
      {
        "nome": "Cafeteria 07",
        "tipo": "png",
        "caminho": estampa7
      },
      {
        "nome": "Cafeteria 08",
        "tipo": "png",
        "caminho": estampa8
      },
      {
        "nome": "Cafeteria 09",
        "tipo": "png",
        "caminho": estampa9
      },
      {
        "nome": "Cafeteria 10",
        "tipo": "png",
        "caminho": estampa10
      },
      {
        "nome": "Cafeteria 11",
        "tipo": "png",
        "caminho": estampa11
      },
      {
        "nome": "Cafeteria 12",
        "tipo": "png",
        "caminho": estampa12
      },
      {
        "nome": "Cafeteria 13",
        "tipo": "png",
        "caminho": estampa13
      },
      {
        "nome": "Cafeteria 14",
        "tipo": "png",
        "caminho": estampa14
      },
      {
        "nome": "Cafeteria 16",
        "tipo": "png",
        "caminho": estampa16
      },
      {
        "nome": "Cafeteria 17",
        "tipo": "png",
        "caminho": estampa17
      },
      {
        "nome": "Cafeteria 18",
        "tipo": "png",
        "caminho": estampa18
      },
      {
        "nome": "Cafeteria 19",
        "tipo": "png",
        "caminho": estampa19
      },
      {
        "nome": "Cafeteria 20",
        "tipo": "png",
        "caminho": estampa20
      },
      {
        "nome": "Cafeteria 21",
        "tipo": "png",
        "caminho": estampa21
      },
      {
        "nome": "Cafeteria 22",
        "tipo": "png",
        "caminho": estampa22
      },
      {
        "nome": "Cafeteria 23",
        "tipo": "png",
        "caminho": estampa23
      },
      {
        "nome": "Cafeteria 24",
        "tipo": "png",
        "caminho": estampa24
      },
    ]
  
  