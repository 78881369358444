import { Box, Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';

const GenericStepQuestion = ({ hasItem, setHasItem,  onNextStep, question , onPreviousStep, briefingFinalizado, firstOption, secondOption, steps, nextSteps}) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <h1 style={{textAlign:"center"}}>{question}</h1>
          <Box display="flex" flexDirection="column">
        <FormGroup>
          <FormControlLabel
          control={
          <Checkbox
            checked={hasItem === true}
            onChange={() => setHasItem(true)}
          />}
          label={firstOption? firstOption :"Sim"}
          />
          <FormControlLabel
          control={
          <Checkbox
            checked={hasItem === false}
            onChange={() => setHasItem(false)}
          />}
          label={ secondOption? secondOption: "Não"}
          />
          </FormGroup>
          </Box>
          <Box display="flex" justifyContent="center" margin="10px" alignItems="center" flexDirection="column">

          <Button type="button" variant="contained"  onClick={() => onNextStep(nextSteps ? nextSteps : 1)} disabled={hasItem === null} color="success">
            Próximo
          </Button>
          <Box height="20px" />
          { !onPreviousStep ? null : <Button variant="contained" type="button" onClick={() => onPreviousStep(steps? steps : 1)} color="secondary">Anterior</Button>} 
          
          </Box>
        </Box>
    );
}

export default GenericStepQuestion;