
import estampa1 from "../DRINKS/DRINKS 01.png";
import estampa2 from  "../DRINKS/DRINKS 02.png";
import estampa3 from "../DRINKS/DRINKS 03.png";
import estampa4 from  "../DRINKS/DRINKS 04.png";
import estampa5 from  "../DRINKS/DRINKS 05.png";
import estampa6 from  "../DRINKS/DRINKS 06.png";
import estampa7 from  "../DRINKS/DRINKS 07.png";
import estampa8 from  "../DRINKS/DRINKS 08.png";
import estampa9 from  "../DRINKS/DRINKS 09.png";
import estampa10 from "../DRINKS/DRINKS 010.png";
import estampa11 from "../DRINKS/DRINKS 011.png";
import estampa12 from "../DRINKS/DRINKS 012.png";
import estampa13 from "../DRINKS/DRINKS 013.png";
import estampa14 from "../DRINKS/DRINKS 014.png";
import estampa15 from "../DRINKS/DRINKS 015.png";


 
 export  const imagensDrink = [
      {
        "nome": "Drink 01",
        "tipo": "png",
        "caminho": estampa1
      },
      {
        "nome": "Drink 02",
        "tipo": "png",
        "caminho": estampa2
      },
      {
        "nome": "Drink 03",
        "tipo": "png",
        "caminho": estampa3
      },
      {
        "nome": "Drink 04",
        "tipo": "png",
        "caminho": estampa4
      },
      {
        "nome": "Drink 05",
        "tipo": "png",
        "caminho": estampa5
      },
      {
        "nome": "Drink 06",
        "tipo": "png",
        "caminho": estampa6
      },
      {
        "nome": "Drink 07",
        "tipo": "png",
        "caminho": estampa7
      },
      {
        "nome": "Drink 08",
        "tipo": "png",
        "caminho": estampa8
      },
      {
        "nome": "Drink 09",
        "tipo": "png",
        "caminho": estampa9
      },
      {
        "nome": "Drink 10",
        "tipo": "png",
        "caminho": estampa10
      },
      {
        "nome": "Drink 11",
        "tipo": "png",
        "caminho": estampa11
      },
      {
        "nome": "Drink 12",
        "tipo": "png",
        "caminho": estampa12
      },
      {
        "nome": "Drink 13",
        "tipo": "png",
        "caminho": estampa13
      },
      {
        "nome": "Drink 14",
        "tipo": "png",
        "caminho": estampa14
      },
      {
        "nome": "Drink 15",
        "tipo": "png",
        "caminho": estampa15
      }
    ]
  
  