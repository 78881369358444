
import estampa1 from  "../SORVETERIA/sorveteria  01.png"
import estampa2 from  "../SORVETERIA/sorveteria  02.png"
import estampa3 from  "../SORVETERIA/sorveteria  03.png"
import estampa4 from  "../SORVETERIA/sorveteria  04.png"
import estampa5 from  "../SORVETERIA/sorveteria  05.png"
import estampa6 from  "../SORVETERIA/sorveteria  06.png"
import estampa7 from  "../SORVETERIA/sorveteria  07.png"
import estampa8 from  "../SORVETERIA/sorveteria  08.png"
import estampa9 from  "../SORVETERIA/sorveteria  09.png"
import estampa10 from "../SORVETERIA/sorveteria  010.png"
import estampa11 from "../SORVETERIA/sorveteria  011.png"
import estampa12 from "../SORVETERIA/sorveteria 012.png"
import estampa13 from "../SORVETERIA/sorveteria 013.png"
import estampa14 from "../SORVETERIA/sorveteria 014.png"
import estampa15 from "../SORVETERIA/sorveteria 015.png"
import estampa16 from "../SORVETERIA/sorveteria 016.png"
import estampa17 from "../SORVETERIA/sorveteria 017.png"
import estampa18 from "../SORVETERIA/sorveteria 018.png"
import estampa19 from "../SORVETERIA/sorveteria 019.png"
import estampa20 from "../SORVETERIA/sorveteria 020.png"
import estampa21 from "../SORVETERIA/sorveteria 021.png"
 
 export  const imagensSorveteria = [
      {
        "nome": "Sorveteria 01",
        "tipo": "png",
        "caminho": estampa1
      },
      {
        "nome": "Sorveteria 02",
        "tipo": "png",
        "caminho": estampa2
      },
      {
        "nome": "Sorveteria 03",
        "tipo": "png",
        "caminho": estampa3
      },
      {
        "nome": "Sorveteria 04",
        "tipo": "png",
        "caminho": estampa4
      },
      {
        "nome": "Sorveteria 05",
        "tipo": "png",
        "caminho": estampa5
      },
      {
        "nome": "Sorveteria 06",
        "tipo": "png",
        "caminho": estampa6
      },
      {
        "nome": "Sorveteria 07",
        "tipo": "png",
        "caminho": estampa7
      },
      {
        "nome": "Sorveteria 08",
        "tipo": "png",
        "caminho": estampa8
      },
      {
        "nome": "Sorveteria 09",
        "tipo": "png",
        "caminho": estampa9
      },
      {
        "nome": "Sorveteria 10",
        "tipo": "png",
        "caminho": estampa10
      },
      {
        "nome": "Sorveteria 11",
        "tipo": "png",
        "caminho": estampa11
      },
      {
        "nome": "Sorveteria 12",
        "tipo": "png",
        "caminho": estampa12
      },
      {
        "nome": "Sorveteria 13",
        "tipo": "png",
        "caminho": estampa13
      },
      {
        "nome": "Sorveteria 14",
        "tipo": "png",
        "caminho": estampa14
      },
      {
        "nome": "Sorveteria 15",
        "tipo": "png",
        "caminho": estampa15
      },
      {
        "nome": "Sorveteria 16",
        "tipo": "png",
        "caminho": estampa16
      },
      {
        "nome": "Sorveteria 17",
        "tipo": "png",
        "caminho": estampa17
      },
      {
        "nome": "Sorveteria 18",
        "tipo": "png",
        "caminho": estampa18
      },
      {
        "nome": "Sorveteria 19",
        "tipo": "png",
        "caminho": estampa19
      },
      {
        "nome": "Sorveteria 20",
        "tipo": "png",
        "caminho": estampa20
      },
      {
        "nome": "Sorveteria 21",
        "tipo": "png",
        "caminho": estampa21
      },
    ]
  