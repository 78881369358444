import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: "80%",
    sm: "70%",
    md: "60%",
    lg: "40%",
    xl: "40%"
  },
  background: 'linear-gradient(to top left, white, #83a802)',
  border: '1px solid #000',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} color="error" variant="contained" >Deseja preencher o briefing novamente?</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="bold" textAlign="center">
            Deseja preencher o briefing desse produto novamente?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} textAlign="center">
            Você perderá todas as informações preenchidas anteriormente e terá que preencher novamente.
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="space-between" justifyItems="center" mt={1} > 
            <Button
              onClick={() => {
                localStorage.removeItem('formData');
                localStorage.removeItem('formState');
                localStorage.removeItem('produto');
                localStorage.removeItem('step');
                window.location.reload();
            }} 
              color="error"
              variant="contained"
              sx={{
                alignSelf: "center",
                width: {
                  xs: "100%",
                  sm: "80%",
                  md: "60%",
                  lg: "50%",
                  xl: "50%"
                }
              }}
            
            >Sim, desejo preencher novamente</Button>
            <Box display="flex" justifyContent="center" margin="10px"/>
            <Button
               onClick={handleClose}
               color="success"
               variant="contained"
               sx={{
                alignSelf: "center",
                width: {
                  xs: "100%",
                  sm: "80%",
                  md: "60%",
                  lg: "50%",
                  xl: "50%"
                }
              }}
               >Não, manter informações</Button>
          </Box>
        </Box>

      </Modal>
    </div>
  );
}
