import { Box } from "@mui/material";
import React from "react";

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <Box
            component="footer"
            // sx={{
            //     width: '100%',
            //     textAlign: 'center',
            //     padding: '10px 0',
            //     marginTop: 'auto', // Isso empurra o rodapé para o final da página
            // }}
        >
            <p>Desenvolvido por Papello © {year}</p>
        </Box>

    );
}

export default Footer;