
import estampa1 from  "../RESTAURANTE - DELIVERY - LANCHONETE/DELIVERY 01.png"
import estampa2 from  "../RESTAURANTE - DELIVERY - LANCHONETE/DELIVERY 02.png"
import estampa3 from  "../RESTAURANTE - DELIVERY - LANCHONETE/DELIVERY 03.png"
import estampa4 from  "../RESTAURANTE - DELIVERY - LANCHONETE/DELIVERY 04.png"
import estampa5 from "../RESTAURANTE - DELIVERY - LANCHONETE/DELIVERY 05.png"
import estampa6 from  "../RESTAURANTE - DELIVERY - LANCHONETE/DELIVERY 06.png"
import estampa7 from  "../RESTAURANTE - DELIVERY - LANCHONETE/DELIVERY 07.png"
import estampa8 from  "../RESTAURANTE - DELIVERY - LANCHONETE/DELIVERY 08.png"
import estampa9 from  "../RESTAURANTE - DELIVERY - LANCHONETE/DELIVERY 09.png"
import estampa10 from "../RESTAURANTE - DELIVERY - LANCHONETE/DELIVERY 010.png"
import estampa11 from "../RESTAURANTE - DELIVERY - LANCHONETE/LANCHONTE 01.png"
import estampa12 from "../RESTAURANTE - DELIVERY - LANCHONETE/LANCHONTE 02.png"
import estampa13 from "../RESTAURANTE - DELIVERY - LANCHONETE/LANCHONTE 03.png"
import estampa14 from"../RESTAURANTE - DELIVERY - LANCHONETE/LANCHONTE 04.png"
import estampa15 from "../RESTAURANTE - DELIVERY - LANCHONETE/LANCHONTE 05.png"
import estampa16 from"../RESTAURANTE - DELIVERY - LANCHONETE/LANCHONTE 06.png"
import estampa17 from "../RESTAURANTE - DELIVERY - LANCHONETE/LANCHONTE 07.png"
import estampa18 from "../RESTAURANTE - DELIVERY - LANCHONETE/LANCHONTE 08.png"
import estampa19 from"../RESTAURANTE - DELIVERY - LANCHONETE/LANCHONTE 09.png"
import estampa20 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 01.png"
import estampa21 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 02.png"
import estampa22 from"../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 03.png"
import estampa23 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 04.png"
import estampa24 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 05.png"
import estampa25 from"../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 06.png"
import estampa26 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 07.png"
import estampa27 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 08.png"
import estampa28 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 09.png"
import estampa29 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 010.png"
import estampa30 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 011.png"
import estampa31 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 012.png"
import estampa32 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 013.png"
import estampa33 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 014.png"
import estampa34 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 015.png"
import estampa35 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 016.png"
import estampa36 from "../RESTAURANTE - DELIVERY - LANCHONETE/RESTAURANTE 017.png"

 
 export  const imagensRestauranteDelivery = [
      {
        "nome": "Restaurante/Delivery 01",
        "tipo": "png",
        "caminho": estampa1
      },
      {
        "nome": "Restaurante/Delivery 02",
        "tipo": "png",
        "caminho": estampa2
      },
      {
        "nome": "Restaurante/Delivery 03",
        "tipo": "png",
        "caminho": estampa3
      },
      {
        "nome": "Restaurante/Delivery 04",
        "tipo": "png",
        "caminho": estampa4
      },
      {
        "nome": "Restaurante/Delivery 05",
        "tipo": "png",
        "caminho": estampa5
      },
      {
        "nome": "Restaurante/Delivery 06",
        "tipo": "png",
        "caminho": estampa6
      },
      {
        "nome": "Restaurante/Delivery 07",
        "tipo": "png",
        "caminho": estampa7
      },
      {
        "nome": "Restaurante/Delivery 08",
        "tipo": "png",
        "caminho": estampa8
      },
      {
        "nome": "Restaurante/Delivery 09",
        "tipo": "png",
        "caminho": estampa9
      },
      {
        "nome": "Restaurante/Delivery 10",
        "tipo": "png",
        "caminho": estampa10
      },
      {
        "nome": "Restaurante/Delivery 11",
        "tipo": "png",
        "caminho": estampa11
      },
      {
        "nome": "Restaurante/Delivery 12",
        "tipo": "png",
        "caminho": estampa12
      },
      {
        "nome": "Restaurante/Delivery 13",
        "tipo": "png",
        "caminho": estampa13
      },
      {
        "nome": "Restaurante/Delivery 14",
        "tipo": "png",
        "caminho": estampa14
      },
      {
        "nome": "Restaurante/Delivery 15",
        "tipo": "png",
        "caminho": estampa15
      },
      {
        "nome": "Restaurante/Delivery 16",
        "tipo": "png",
        "caminho": estampa16
      },
      {
        "nome": "Restaurante/Delivery 17",
        "tipo": "png",
        "caminho": estampa17
      },
      {
        "nome": "Restaurante/Delivery 18",
        "tipo": "png",
        "caminho": estampa18
      },
      {
        "nome": "Restaurante/Delivery 19",
        "tipo": "png",
        "caminho": estampa19
      },
      {
        "nome": "Restaurante/Delivery 20",
        "tipo": "png",
        "caminho": estampa20
      },
      {
        "nome": "Restaurante/Delivery 21",
        "tipo": "png",
        "caminho": estampa21
      },
      {
        "nome": "Restaurante/Delivery 22",
        "tipo": "png",
        "caminho": estampa22
      },
      {
        "nome": "Restaurante/Delivery 23",
        "tipo": "png",
        "caminho": estampa23
      },
      {
        "nome": "Restaurante/Delivery 24",
        "tipo": "png",
        "caminho": estampa24
      },
      {
        "nome": "Restaurante/Delivery 25",
        "tipo": "png",
        "caminho": estampa25
      },
      {
        "nome": "Restaurante/Delivery 26",
        "tipo": "png",
        "caminho": estampa26
      },
      {
        "nome": "Restaurante/Delivery 27",
        "tipo": "png",
        "caminho": estampa27
      },
      {
        "nome": "Restaurante/Delivery 28",
        "tipo": "png",
        "caminho": estampa28
      },
      {
        "nome": "Restaurante/Delivery 29",
        "tipo": "png",
        "caminho": estampa29
      },
      {
        "nome": "Restaurante/Delivery 30",
        "tipo": "png",
        "caminho": estampa30
      },
      {
        "nome": "Restaurante/Delivery 31",
        "tipo": "png",
        "caminho": estampa31
      },
      {
        "nome": "Restaurante/Delivery 32",
        "tipo": "png",
        "caminho": estampa32
      },
      {
        "nome": "Restaurante/Delivery 33",
        "tipo": "png",
        "caminho": estampa33
      },
      {
        "nome": "Restaurante/Delivery 34",
        "tipo": "png",
        "caminho": estampa34
      },
      {
        "nome": "Restaurante/Delivery 35",
        "tipo": "png",
        "caminho": estampa35
      },
      {
        "nome": "Restaurante/Delivery 36",
        "tipo": "png",
        "caminho": estampa36
      },
    ]
  