import { Box, Checkbox, FormControlLabel, Link, Typography } from "@mui/material";

const Termos = ({setTermos, termos}) => {
    return (
        <Box textAlign="center">
            <FormControlLabel
              control={
                <Checkbox
                  value="Eu concordo com os termos de uso."
                  name="Eu concordo com os termos de uso."
                  checked={termos}
                  onChange={(e) => setTermos(e.target.checked)}
                />
              }
              label={
                <>
                  <Box display="flex" alignItems="center" justifyContent="center" textAlign="center">
                      <Typography variant="body1" fontFamily="Montserrat">Eu concordo com os termos de uso.</Typography>
                  </Box>
                  <Link  href="https://papello.com.br/termos-de-uso" target="_blank" fontFamily="Montserrat">*Ler termos de uso </Link>
                </>
              }
            />
            
          </Box>
    )

}

export default Termos;