
import estampa1 from "../DOCERIA/Doceria 01.png";
import estampa2 from "../DOCERIA/Doceria 02.png";
import estampa3 from "../DOCERIA/Doceria 03.png";
import estampa4 from "../DOCERIA/Doceria 04.png";
import estampa5 from "../DOCERIA/Doceria 05.png";
import estampa6 from "../DOCERIA/Doceria 06.png";
import estampa7 from "../DOCERIA/Doceria 07.png";
import estampa8 from "../DOCERIA/Doceria 08.png";
import estampa9 from "../DOCERIA/Doceria 09.png";
import estampa10 from "../DOCERIA/Doceria 010.png";
import estampa11 from "../DOCERIA/Doceria 011.png";
import estampa12 from "../DOCERIA/doceria 012.png";
import estampa13 from "../DOCERIA/doceria 013.png";
import estampa14 from "../DOCERIA/Doceria 014.png";
import estampa15 from "../DOCERIA/Doceria 015.png";
import estampa16 from "../DOCERIA/Doceria 016.png";
import estampa17 from "../DOCERIA/Doceria 017.png";
import estampa18 from "../DOCERIA/Doceria 018.png";
import estampa19 from "../DOCERIA/Doceria 019.png";
import estampa20 from "../DOCERIA/Doceria 020.png";

 
 export  const imagensDoceria = [
      {
        "nome": "Doceria 01",
        "tipo": "png",
        "caminho": estampa1
      },
      {
        "nome": "Doceria 02",
        "tipo": "png",
        "caminho": estampa2
      },
      {
        "nome": "Doceria 03",
        "tipo": "png",
        "caminho": estampa3
      },
      {
        "nome": "Doceria 04",
        "tipo": "png",
        "caminho": estampa4
      },
      {
        "nome": "Doceria 05",
        "tipo": "png",
        "caminho": estampa5
      },
      {
        "nome": "Doceria 06",
        "tipo": "png",
        "caminho": estampa6
      },
      {
        "nome": "Doceria 07",
        "tipo": "png",
        "caminho": estampa7
      },
      {
        "nome": "Doceria 08",
        "tipo": "png",
        "caminho": estampa8
      },
      {
        "nome": "Doceria 09",
        "tipo": "png",
        "caminho": estampa9
      },
      {
        "nome": "Doceria 10",
        "tipo": "png",
        "caminho": estampa10
      },
      {
        "nome": "Doceria 11",
        "tipo": "png",
        "caminho": estampa11
      },
      {
        "nome": "Doceria 12",
        "tipo": "png",
        "caminho": estampa12
      },
      {
        "nome": "Doceria 13",
        "tipo": "png",
        "caminho": estampa13
      },
      {
        "nome": "Doceria 14",
        "tipo": "png",
        "caminho": estampa14
      },
      {
        "nome": "Doceria 15",
        "tipo": "png",
        "caminho": estampa15
      },
      {
        "nome": "Doceria 16",
        "tipo": "png",
        "caminho": estampa16
      },
      {
        "nome": "Doceria 17",
        "tipo": "png",
        "caminho": estampa17
      },
      {
        "nome": "Doceria 18",
        "tipo": "png",
        "caminho": estampa18
      },
      {
        "nome": "Doceria 19",
        "tipo": "png",
        "caminho": estampa19
      },
      {
        "nome": "Doceria 20",
        "tipo": "png",
        "caminho": estampa20
      }
    ]
  
  