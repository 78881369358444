
import estampa1 from  "../MILK SHAKE/Milk shake 01.png"
import estampa2 from  "../MILK SHAKE/Milk shake 02.png"
import estampa3 from  "../MILK SHAKE/Milk shake 03.png"
import estampa4 from  "../MILK SHAKE/Milk shake 04.png"
import estampa5 from  "../MILK SHAKE/Milk shake 05.png"
import estampa6 from  "../MILK SHAKE/Milk shake 06.png"
import estampa7 from  "../MILK SHAKE/Milk shake 07.png"
import estampa8 from  "../MILK SHAKE/Milk shake 08.png"
import estampa9 from  "../MILK SHAKE/Milk shake 09.png"
import estampa10 from "../MILK SHAKE/Milk shake 010.png"
import estampa11 from "../MILK SHAKE/Milkshake 011.png"
import estampa12 from "../MILK SHAKE/Milkshake 012.png"
import estampa13 from "../MILK SHAKE/Milkshake 013.png"
import estampa14 from "../MILK SHAKE/Milkshake 014.png"
import estampa15 from "../MILK SHAKE/Milkshake 015.png"
import estampa16 from "../MILK SHAKE/Milk shake 016.png"
import estampa17 from "../MILK SHAKE/Milk shake 017.png"
import estampa18 from "../MILK SHAKE/Milk shake 018.png"
import estampa19 from "../MILK SHAKE/Milk shake 019.png"
import estampa20 from "../MILK SHAKE/Milk shake 020.png"
 
 export  const imagensMilkShake = [
      {
        "nome": "Milk Shake 01",
        "tipo": "png",
        "caminho": estampa1
      },
      {
        "nome": "Milk Shake 02",
        "tipo": "png",
        "caminho": estampa2
      },
      {
        "nome": "Milk Shake 03",
        "tipo": "png",
        "caminho": estampa3
      },
      {
        "nome": "Milk Shake 04",
        "tipo": "png",
        "caminho": estampa4
      },
      {
        "nome": "Milk Shake 05",
        "tipo": "png",
        "caminho": estampa5
      },
      {
        "nome": "Milk Shake 06",
        "tipo": "png",
        "caminho": estampa6
      },
      {
        "nome": "Milk Shake 07",
        "tipo": "png",
        "caminho": estampa7
      },
      {
        "nome": "Milk Shake 08",
        "tipo": "png",
        "caminho": estampa8
      },
      {
        "nome": "Milk Shake 09",
        "tipo": "png",
        "caminho": estampa9
      },
      {
        "nome": "Milk Shake 10",
        "tipo": "png",
        "caminho": estampa10
      },
      {
        "nome": "Milk Shake 11",
        "tipo": "png",
        "caminho": estampa11
      },
      {
        "nome": "Milk Shake 12",
        "tipo": "png",
        "caminho": estampa12
      },
      {
        "nome": "Milk Shake 13",
        "tipo": "png",
        "caminho": estampa13
      },
      {
        "nome": "Milk Shake 14",
        "tipo": "png",
        "caminho": estampa14
      },
      {
        "nome": "Milk Shake 15",
        "tipo": "png",
        "caminho": estampa15
      },
      {
        "nome": "Milk Shake 16",
        "tipo": "png",
        "caminho": estampa16
      },
      {
        "nome": "Milk Shake 17",
        "tipo": "png",
        "caminho": estampa17
      },
      {
        "nome": "Milk Shake 18",
        "tipo": "png",
        "caminho": estampa18
      },
      {
        "nome": "Milk Shake 19",
        "tipo": "png",
        "caminho": estampa19
      },
      {
        "nome": "Milk Shake 20",
        "tipo": "png",
        "caminho": estampa20
      },
    ]
  